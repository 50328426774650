import styled from "styled-components";

export const VideoWrap = styled.div`
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
